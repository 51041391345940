import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SideComponent } from './layout/side/side.component';
import { HiddenTabRightComponent } from './layout/hidden-tab-right/hidden-tab-right.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { SliderComponent } from './layout/slider/slider.component';
import { SliderForHomeComponent } from './pages/home/slider-for-home/slider-for-home.component';
import { EventsComponent } from './pages/events/events.component';
import { MainPageComponent } from './layout/main-page/main-page.component';
import { SigninComponent } from './layout/header/signin/signin.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AboutSectionComponent } from './pages/home/about/aboutSection.component';
import { SchoolsSelectComponent } from './layout/header/schools-select/schools-select.component';
import { basicInterceptor } from './core/helpers/basic_Interceptor';
import { LatestEventsComponent } from './pages/home/slider-for-home/latest-events/latest-events.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NewsComponent } from './pages/news/news.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageSelectComponent } from './layout/header/language-select/language-select.component';
import { SchoolStatisticsComponent } from './pages/home/school-statistics/school-statistics.component';
import { SchoolLogoAndNameComponent } from './layout/header/school-logo-and-name/school-logo-and-name.component';
import { ApplyNowComponent } from './pages/apply-now/apply-now.component';
import { AboutComponent } from './pages/about/about.component';
import { RedirectComponent } from './redirect/redirect.component';
import { EventDetailsComponent } from './pages/events/event-details/event-details.component';
import { NewDetailsComponent } from './pages/news/new-details/new-details/new-details.component'
import { newsSectionComponent } from './pages/home/newsSection/newsSection.component';
import { mapSectionComponent } from './pages/home/mapSection/mapSection.component';
import { SafePipe } from './Pipes/safe.pipe';
import { JobDetailsComponent } from './pages/jobs/job-details/job-details.component';
import { ModalComponent } from './modal/modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule  } from '@angular/material/sort';

// import { MdbModalRef ,MdbModalService } from 'mdb-angular-ui-kit/modal';
// import { DataTablesModule } from 'angular-datatables';
// import { CommonModule } from '@angular/common';
export const createtranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, '/assets/i18/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideComponent,
    HiddenTabRightComponent,
    NavbarComponent,
    HomeComponent,
    SliderComponent,
    SliderForHomeComponent,
    EventsComponent,
    MainPageComponent,
    SigninComponent,
    AboutComponent,
    SchoolsSelectComponent,
    LatestEventsComponent,
    NewsComponent,
    LanguageSelectComponent,
    SchoolStatisticsComponent,
    SchoolLogoAndNameComponent,
    ApplyNowComponent,
    AboutSectionComponent,
    RedirectComponent,
    EventDetailsComponent,
    NewDetailsComponent,
    newsSectionComponent,
    mapSectionComponent,
    SafePipe,
    JobsComponent,
    JobDetailsComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    // MatSliderModule,
    MatTableModule,
    MatSortModule,
    // MdbModalRef ,MdbModalService,
    // DataTablesModule,
    // CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createtranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: basicInterceptor, multi: true },Title],

  bootstrap: [AppComponent]
})
export class AppModule { }
