<div class="Content2Column">
  <h1>{{ 'news' | translate }}</h1>
  <div class="box-grid-list">
    <a id="add-list" class="active"><img src="assets/img/icons/ic-list.png" alt="Show list"></a>
    <a id="add-grid" class=""><img src="assets/img/icons/ic-grid.png" alt="Show Grid"></a>
  </div>
  <ul id="NewsListing" class="list-view">
    <li *ngFor="let newsItem of newsData| paginate: config">
      <a href="#">
        <img src="{{domainUrl+newsItem.imgUrl}}" alt="">
      </a>
      <div class="text-box">
        <span class="date">{{newsItem.date|date:"dd/MM/yyyy"}}</span>
        <span class="title"><a href="#" (click)="NavigateToNewDetails(newsItem)">{{newsItem.title}}</a></span>
        <span class="page-overview">{{newsItem.text}}</span>
      </div>
    </li>
  </ul>
  <pagination-controls [responsive]=true previousLabel="{{'previos'|translate}}" nextLabel="{{'next'|translate}}"
    (pageChange)="pageChange($event)">
  </pagination-controls>
</div>


<!--side menue-->
<div class="ColumnLeft">
  <nav id="SideHeadingPages" class="SideHeading">
    <h2>{{'RelatedPages'|translate}}</h2>
    <ul class="SideList">
      <li><a style="text-align: center;" routerLink="" id="nav1125">{{'home'|translate}}</a></li>
      <li><a style="text-align: center;" routerLink="/about" id="nav1126">{{'about'|translate}}</a></li>
      <li><a style="text-align: center;" routerLink="/news" id="nav1127">{{'news'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/events" id="nav1127">{{'events'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/apply" id="nav1127">{{'applynow'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/jobs" id="nav1127">{{'Jobs'|translate}}</a> </li>

    </ul>
  </nav>
</div>
